import React from "react";
import { Text, Heading, Img } from "./..";
import Cache from "utils/cache";

export default function Footer({ ...props }) {
	let platform;
	if (props.userData) {
		platform = props.userData.platform;
	} else {
		platform = Cache.getData()?.platform;
	}

	return (
		<footer
			{ ...props }
			className={ `${ platform === 'app' ? 'hidden' : 'block' } footer-pin w-full bg-blue_gray-800` }
		>
			<div className="flex flex-col items-center justify-start w-full border-t border-blue_gray-700">
				<Text size="xs" as="p" className="!font-inter py-5">
					Copyright © SedeqahTech 2024
				</Text>
			</div>

			{/* <div className="flex flex-col items-center justify-center w-full mt-20 gap-10">
				<div className="flex flex-row justify-start items-start w-full gap-[34px] max-w-[480px] px-10">
					<div className="flex flex-col items-start justify-start w-[31%] gap-[30px]">
						<Img
							src="/images/logo.svg"
							alt="vector_one"
							className=""
						/>
						<Text as="p" size="s">
							Care about people to transforming their lives
							and exudes a positives Impressiono believe. fundraising
							NGO organizations.
						</Text>
						<Img src="/images/img_follow.svg" alt="follow_one" className="h-[60px]" />
					</div>
					<div className="flex flex-row justify-center w-[67%] py-[49px] px-[49px] bg-blue_gray-800_01">
						<div className="grid grid-cols-1 w-full">						
							<div className="flex flex-col items-start justify-start gap-5">
								<Heading as="h4" className="!text-white-A700 !font-bold">
									Contact
								</Heading>
								<Text as="p" className="!leading-[186%] !text-lg">
									660 Broklyn Street, 88 New York
									<br />
									<br />
									help Need@walfare.com
									<br />
									222 888 0000
								</Text>
							</div>
						</div>
					</div>
				</div>
				
			</div> */}
		</footer >
	);
}
