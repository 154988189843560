import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Text } from "components";
import * as loader from "components/Loading/loading";
import API from "api/Api";
import { NumericFormat } from "react-number-format";
import ModalPayment from "./ModalPayment";
import { format } from 'date-fns';
import Cache from "utils/cache";
import axiosInstance from 'api/axiosInstance';

export default function Register() {
	const [transactions, setTransactions] = useState([]);
	const [activePayment, setActivePayment] = useState(null);
	const [expandedRows, setExpandedRows] = useState([]);
	const [tokenReady, setTokenReady] = useState(false);
	const [userData, setUserData] = useState(null);
	const handleCloseModal = () => {
		setActivePayment(null);
	};

	useEffect(() => {
		charityAPI();
		const urlParams = new URLSearchParams(window.location.search);
		const token = decodeURIComponent(urlParams.get('xtoken'));
		if (token) {
			getProfile(token);
			const platform = urlParams.get('platform');
			getBalance(token, platform);

		}
	}, []);
	const getBalance = async (token, platform) => {
		const currentUrl = window.location.href;
		const apiUrl = currentUrl.includes('stage') ? 'https://stage-ob.aix-exchange.com/api/users/coins' : 'https://ob.aix.asia/api/users/coins';
		loader.showLoader();
		setTokenReady(false);
		try {
			const e = await axiosInstance.get(apiUrl, {
				headers: {
					'Content-Type': 'application/json',
					'x-api-version': "1.4.24",
					xtoken: `${ token }`
				}
			});
			const usdtData = e.data.data.find(item => item.coin.symbol === "USDT");
			const dataUser = { xtoken: token, balance: usdtData.availableBalance, platform: platform };
			Cache.setData(dataUser);
			setUserData(dataUser);
			setTokenReady(true);
		} catch (e_1) {
			console.log(e_1);
			setTokenReady(true);
		}
	};
	const getProfile = async (data) => {
		const currentUrl = window.location.href;
		const apiUrl = currentUrl.includes('stage') ? 'https://stage-ob.aix-exchange.com/api/auth' : 'https://ob.aix.asia/api/auth';
		loader.showLoader();
		setTokenReady(false);
		return axiosInstance.get(apiUrl, {
			headers: {
				'Content-Type': 'application/json',
				'x-api-version': "1.4.24",
				xtoken: `${ data }`
			}
		})
			.then(e => {
				setLoginUser(e.data.data);
				setTokenReady(true);
			})
			.catch(e => {
				if (e.response.data.code === 401) {
					Cache.clearLogin();
					console.log("clear");
				}
				setTokenReady(true);
				loader.hideLoader();
			});
	};

	const setLoginUser = (e) => {
		let data = {};
		data.fullname = e.fullName;
		data.email = e.email;
		data.phone = e.phone;
		data.user_type = e.account_type;
		data.unique_id = e.referralCode;
		API.post(`force-login`, data).then(e => {
			loader.hideLoader();
			Cache.setUser(e.data);
			Cache.setAccessToken(e.data.token);
			setTokenReady(true);
		}).catch(e => {
			loader.hideLoader();
			setTokenReady(true);
		});
	};
	const charityAPI = () => {
		loader.showLoader();
		API.get(`transactions?limit=9999999`).then(e => {
			loader.hideLoader();
			setTransactions(e.data.transactions || []);
		}).catch(e => {
			loader.hideLoader();
		});
	};

	const toggleRow = (id) => {
		setExpandedRows(prev =>
			prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
		);
	};

	return (
		<div className="flex flex-col items-center justify-start w-full  bg-white-A700">
			<div className="relative w-full">
				{ tokenReady &&
					<Header showBackButton={ false } userData={ userData } />
				}
			</div>
			<div className="py-20 gap-10  max-w-[940px] w-full md:w-[940px] px-5 mt-10 min-h-screen-min-topbar">
				<Text size="lg" as="p" className="!text-blue !font-sueellenfrancisco">
					My Transactions
				</Text>
				<div className="flex flex-row justify-start items-center gap-5 w-full mt-8">
					<div className="flex flex-col w-full">
						<div className="-m-1.5 overflow-x-auto">
							<div className="p-1.5 min-w-full inline-block align-middle">
								<div className="border overflow-hidden">
									<table className="min-w-full divide-y divide-gray-200">
										<thead>
											<tr>
												<th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase">Charity</th>
												<th scope="col" className="px-3  py-3 text-left text-xs font-medium text-gray-500 uppercase">Amount</th>
												<th scope="col" className="px-3  py-3 text-left text-xs font-medium text-gray-500 uppercase">Date</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200">
											{ transactions.map((record) => (
												<React.Fragment key={ record.id }>
													<tr>

														<td className="px-3 py-4 text-sm text-gray-800">
															<Text size="sm" as="p" className="!text-red-300">
																{ record.institution_name }
															</Text>
															{ record.charity_name }
														</td>
														<td className="px-3 py-4 text-sm font-medium text-gray-800">	<NumericFormat value={ `${ record.amount || '0' }` } displayType={ 'text' } thousandSeparator={ true } prefix={ '$' } /></td>
														<td className="px-3 py-4 text-sm font-medium text-gray-800">{ format(new Date(record.created_at), 'MMMM dd yyyy') }</td>
														<td>
															<button
																className={ `${ expandedRows.includes(record.id) && 'rotate-180' } inline-flex items-center px-3 gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none mt-2` }
																onClick={ () => toggleRow(record.id) }
															>
																<svg
																	className="fill-current"
																	width="12"
																	height="8"
																	viewBox="0 0 12 8"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		fillRule="evenodd"
																		clipRule="evenodd"
																		d="M0.410765 0.910734C0.736202 0.585297 1.26384 0.585297 1.58928 0.910734L6.00002 5.32148L10.4108 0.910734C10.7362 0.585297 11.2638 0.585297 11.5893 0.910734C11.9147 1.23617 11.9147 1.76381 11.5893 2.08924L6.58928 7.08924C6.26384 7.41468 5.7362 7.41468 5.41077 7.08924L0.410765 2.08924C0.0853277 1.76381 0.0853277 1.23617 0.410765 0.910734Z"
																		fill=""
																	/>
																</svg>
															</button>
														</td>
													</tr>
													{ expandedRows.includes(record.id) && (
														<tr className="">
															<td colSpan="8" className="px-6 py-4  text-sm text-gray-800">
																<div className="flex flex-col gap-2">
																	<div>
																		<strong>Transaction ID:</strong> { record.id }
																	</div>
																	<div>
																		<strong>Transaction Hash:</strong> { record.proof_of_payment_hash }
																	</div>
																	<div>
																		<strong>Amount:</strong> <NumericFormat value={ `${ record.amount || '0' }` } displayType={ 'text' } thousandSeparator={ true } prefix={ '$' } />
																	</div>
																	<div>
																		<strong>Status:</strong> { record.status === 'unpaid' ? 'pending' : record.status }
																	</div>
																	<div>
																		<strong>Date:</strong> { format(new Date(record.created_at), 'MMMM dd yyyy, h:mm:ss a') }
																	</div>

																</div>
															</td>
														</tr>
													) }
												</React.Fragment>
											)) }
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* { activePayment != null && <ModalPayment isOpen={ true } handleCloseModal={ handleCloseModal } order={ activePayment } /> } */ }

			{ tokenReady && <Footer userData={ userData } /> }
		</div>
	);
}
