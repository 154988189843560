import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Hero, AboutUs, WhatWeDo, Foundations } from "layout/Home";
import API from 'api/Api';
import Cache from "utils/cache";
import axiosInstance from 'api/axiosInstance';
import * as loader from "components/Loading/loading";

const Home1Page = () => {
	const [foundations, setFoundations] = useState([]);
	const [tokenReady, setTokenReady] = useState(false);
	const [userData, setUserData] = useState(null);
	const getProfile = async (data) => {
		const currentUrl = window.location.href;
		const apiUrl = currentUrl.includes('stage') ? 'https://stage-ob.aix-exchange.com/api/auth' : 'https://ob.aix.asia/api/auth';
		loader.showLoader();
		setTokenReady(false);
		try {
			const e = await axiosInstance.get(apiUrl, {
				headers: {
					'Content-Type': 'application/json',
					'x-api-version': "1.4.24",
					xtoken: `${ data }`
				}
			});

			loader.hideLoader();
			setLoginUser(e.data.data);
			setTokenReady(true);
		} catch (e_2) {
			setTokenReady(true);
			loader.hideLoader();
		}
	};
	const getBalance = async (token, platform) => {
		const currentUrl = window.location.href;
		const apiUrl = currentUrl.includes('stage') ? 'https://stage-ob.aix-exchange.com/api/users/coins' : 'https://ob.aix.asia/api/users/coins';
		loader.showLoader();
		setTokenReady(false);
		try {
			const e = await axiosInstance.get(apiUrl, {
				headers: {
					'Content-Type': 'application/json',
					'x-api-version': "1.4.24",
					xtoken: `${ token }`
				}
			});
			const usdtData = e.data.data.find(item => item.coin.symbol === "USDT");
			const dataUser = { xtoken: token, balance: usdtData.availableBalance, platform: platform };
			Cache.setData(dataUser);
			setUserData(dataUser);
			setTokenReady(true);
		} catch (e_1) {
			console.log(e_1);
		}
	};

	const getAPIInstitutions = () => {
		API.get('foundations?limit=10&page=1&filter=').then(e => {
			setFoundations(e.data.foundations);
		}).catch(e => {
			console.log(e);
		});
	};
	const setLoginUser = (e) => {
		loader.showLoader();
		setTokenReady(false);
		let data = {};
		data.fullname = e.fullName;
		data.email = e.email;
		data.phone = e.phone;
		data.user_type = e.account_type;
		data.unique_id = e.referralCode;
		API.post(`force-login`, data).then(e => {
			loader.hideLoader();
			Cache.setUser(e.data);
			Cache.setAccessToken(e.data.token);
			setTokenReady(true);
		}).catch(e => {
			loader.hideLoader();
			setTokenReady(true);
		});
	};
	useEffect(() => {
		getAPIInstitutions();
		const urlParams = new URLSearchParams(window.location.search);
		const token = decodeURIComponent(urlParams.get('xtoken'));
		if (token) {
			getProfile(token);
			const platform = urlParams.get('platform');
			getBalance(token, platform);
		}

	}, []);

	return (
		<div className="flex flex-col items-center justify-start w-full bg-white-A700">
			<div className="relative w-full">
				{ tokenReady &&
					<Header showBackButton={ false } userData={ userData } />
				}
			</div>
			<Hero />
			{/* <Brands /> */ }
			<AboutUs />
			<WhatWeDo />
			{/* <ModalUnauthorized isOpen={modalIsOpen()} onButtonClick={handleCloseModal} /> */ }
			{ foundations.length > 0 && <Foundations foundations={ foundations } /> }
			{/* <Help /> */ }
			{ tokenReady &&
				<Footer userData={ userData } />
			}
		</div>
	);
};

export default Home1Page;
